body {
    margin: 0;
    text-transform: capitalize;
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    color: #333;
    background-color: #f9f9f9;
}

#containerProduct {
    display: flex;
    flex-direction: column;
    padding-top: 80px;
    min-height: calc(100vh - 200px);
    /* background: linear-gradient(135deg, #ffffff, #f0f0f0); */
    border-top: 2px solid #ddd;
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Product Section with Animation */
#containerD {
    width: 90%;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 30px;
    padding: 80px 40px;
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    overflow: hidden;

    /* Animation */
    opacity: 0; /* Initially hidden */
    animation: fadeInUp 0.8s ease-out forwards;

    
}

/* Keyframe Animation */
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}



/* Image Section */
#imageSection {
    width: 100%;
    text-align: center;
}

#imageSection img {
    width: 100%;
    max-width: 500px;
    /* Limit the maximum width */
    height: auto;
    border-radius: 15px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
    /* Stronger shadow */
    transition: transform 0.4s ease, box-shadow 0.4s ease;
}

#imageSection img:hover {
    transform: scale(1.1);
    /* Bigger zoom effect */
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
    /* Enhanced shadow on hover */
}

/* Product Details Section */
#productDetails {
    width: 100%;
    padding: 20px;
}

h2 {
    font-size: 28px;
    color: #222;
    margin-bottom: 15px;
    font-weight: bold;
    letter-spacing: 1px;
}

h4 {
    font-size: 16px;
    font-weight: bold;
    color: #007BFF;
    margin-bottom: 15px;
}

/* Product Preview Images */
#productPreview {
    display: flex;
    flex-wrap: wrap;
    /* Ensure thumbnails wrap on smaller screens */
    gap: 15px;
    /* Increased spacing for larger thumbnails */
    margin-bottom: 20px;
    justify-content: center;
}

#productPreview>img {
    width: 70px;
    /* Larger thumbnails */
    height: 70px;
    /* Consistent size */
    padding: 8px;
    border-radius: 12px;
    border: 3px solid transparent;
    cursor: pointer;
    transition: transform 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    /* Shadow for preview images */
}

#productPreview>img:hover {
    transform: scale(1.2);
    /* Zoom on hover */
    border-color: #007BFF;
    /* Highlight border */
    box-shadow: 0 4px 15px rgba(0, 123, 255, 0.2);
    /* Stronger shadow */
}

/* Description Section */
#details h3 {
    font-size: 22px;
    color: #555;
    margin-bottom: 10px;
    font-weight: bold;
    text-transform: uppercase;
}

p {
    font-size: 15px;
    line-height: 1.8;
    color: #666;
}

/* Buttons */
button {
    padding: 12px 20px;
    color: white;
    background-color: #007BFF;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    box-shadow: 0 4px 10px rgba(0, 123, 255, 0.3);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

button:hover {
    background-color: #0056b3;
    box-shadow: 0 6px 15px rgba(0, 123, 255, 0.5);
}

/* Mobile Responsiveness */
@media (max-width: 900px) {
    #containerD {
        grid-template-columns: 1fr;
        /* Stack sections vertically */
        width: 95%;
        padding: 40px 20px;
    }

    #imageSection img {
        width: 80%;
        /* Reduce image width for smaller devices */
        max-width: 400px;
        margin: 0 auto;
    }

    #productDetails {
        padding: 10px;
    }

    h2 {
        font-size: 22px;
    }

    h3 {
        font-size: 18px;
    }

    p {
        font-size: 14px;
    }

    #productPreview>img {
        width: 60px;
        /* Slightly smaller thumbnails */
        height: 60px;
    }
}

@media (max-width: 600px) {
    #containerD {
        width: 100%;
    }

    h2 {
        font-size: 20px;
    }

    p {
        font-size: 13px;
    }

    button {
        padding: 8px 12px;
        font-size: 14px;
    }
}