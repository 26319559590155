/* CSS for the form container */
.form-container {
  max-width: 500px; /* Adjust the max-width as needed */
  margin: 0 auto; /* Center the form horizontally */
  padding: 20px;
}

/* CSS for form labels */
.form-container label {
  display: block;
  margin-top: 10px;
}

/* CSS for form inputs and textarea */
.form-container input[type="text"],
.form-container input[type="email"],
.form-container textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

/* CSS for form submit button */
.form-container input[type="submit"] {
  width: 100%;
  padding: 10px;
  border: none;
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;
}

/* CSS for responsive layout */
@media screen and (max-width: 600px) {
  .form-container {
    max-width: 100%; /* Make the form container full width */
    padding: 10px;
  }
  
  .form-container input[type="submit"] {
    margin-top: 10px;
  }
}

/* Popup Notification */
.popup {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: #28a745;
  color: white;
  padding: 15px 20px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  z-index: 1000;
  animation: fadeInOut 3s ease-in-out;
}

.popup.error {
  background: #dc3545;
}

@keyframes fadeInOut {
  0% { opacity: 0; transform: translateX(-50%) translateY(-10px); }
  10% { opacity: 1; transform: translateX(-50%) translateY(0); }
  90% { opacity: 1; transform: translateX(-50%) translateY(0); }
  100% { opacity: 0; transform: translateX(-50%) translateY(-10px); }
}

/* Responsive Form */
.form-container {
  max-width: 500px;
  margin: 20px auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-container label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.form-container input,
.form-container textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.form-container input[type="submit"] {
  background: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.form-container input[type="submit"]:hover {
  background: #0056b3;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .form-container {
      width: 90%;
      padding: 15px;
  }
}

@media (max-width: 480px) {
  .form-container input,
  .form-container textarea {
      font-size: 14px;
  }

  .popup {
      font-size: 14px;
      padding: 10px;
  }
}
