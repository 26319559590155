footer {
  background: linear-gradient(90deg, #dfee94, #dd5d5d); /* Gradient background */
  }
  
  footer h5 {
    color: #0f0000;
    font-weight: 700;
    font-size: 1.2rem;
  }
  
  footer li {
    padding-bottom: 4px;
  }
  
  footer li a {
    font-size: 0.8rem;
    color: #ff0000;
  }
  
  footer li a:hover {
    color: #000000;
  }
  
  footer p {
    color: #000000;
    font-size: 0.8;
  }
  
  footer .copyright a {
    color: #000;
    width: 38px;
    height: 38px;
    background-color: #fff;
    display: inline-block;
    text-align: center;
    line-height: 38px;
    border-radius: 50%;
    transition: 0.3s ease;
    margin: 0 5px;
  }
  
  footer .copyright a:hover {
    color: #fff;
    background-color: #fb774b;
  }
  