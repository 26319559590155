/* new image */
.home-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-top: 80px;
  /* Adjust according to your navbar height */
}

.image-wrapper {
  width: 100%;
}

.responsive-image {
  width: 100%;
  height: auto;
  /* Maintain aspect ratio */
  display: block;
  object-fit: cover;
  /* Ensures the image covers the space beautifully */
}

/* Media queries for further adjustments on smaller screens */
@media (max-width: 768px) {
  .responsive-image {
    object-fit: contain;
    /* Optional: Adjust for smaller devices */
  }
}


/* sliderr main */
.container {
  max-width: 100%;
  padding: 0 20px;
}

.slick-slider {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  margin: 0 auto;
}

.slick-slide img {
  width: 100%;
  height: auto;
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

/* client  */
#brand>div>div>div>div>div>div>img {
  height: 15vh;
}

/*  section */
.product_type .container {
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.product_type .container .box {
  padding: 10px;
  margin: 10px;
  flex-basis: calc(25% - 20px);
  /* Adjust the value as needed for spacing between boxes */
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.product_type .container .box .img_box {
  height: 200px;
  width: 250px;
  border-radius: 10%;
  background: #e8f4ff;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  /* Add a box shadow for visual effect */
}

.product_type .container .box .img_box img {
  transition: 0.3s ease;
  height: 100%;
  width: 100%;
  cursor: pointer;
  object-fit: cover;
}

.product_type .container .box:hover .img_box img {
  transform: scale(1.3) rotate(-10deg);
  opacity: 0.8;
}

.product_type .container .box .detail {
  margin-top: 10px;
}

.product_type .container .box .detail p {
  color: #000000;
}

/* product */
.product {
  cursor: pointer;
  margin-bottom: 2rem;
}

.product img {
  transition: 0.3s all;
  height: 45vh;
}

.product:hover img {
  opacity: 0.7;
}

.product .buy-btn {
  background: #fb774b;
  transform: translateY(20px);
  opacity: 0;
  transition: 0.3s all;
}

.product:hover .buy-btn {
  transform: translateY(20px);
  opacity: 1;

}

button {
  font-size: 0.8rem;
  font-weight: 700;
  outline: none;
  border: none;
  background-color: #1d1d1d;
  color: aliceblue;
  padding: 13px 30px;
  cursor: pointer;
  text-transform: uppercase;
  transition: 0.3s ease
}

button:hover {
  background-color: #3a3833;
}

/* Styling for the scrolling line inside home-background */
.scrolling-line {
  background-color: #ffcc00;
  /* Bright background for visibility */
  color: #000;
  /* Dark text for contrast */
  font-size: 1rem;
  /* Default font size */
  text-align: center;
  padding: 10px 0;
  /* Padding for spacing */
  margin-bottom: 20px;
  /* Space below the scrolling line */
  overflow: hidden;
  white-space: nowrap;
  /* Prevent wrapping */
  width: 100%;
  position: absolute;
  left: 0;
  z-index: 10;
  /* Ensure it's above the home content */
}

/* Scrolling animation for text */
.scrolling-line span {
  display: inline-block;
  /* animation: scrollText 3s linear infinite; */
  white-space: nowrap;
}

/* Define the scrolling effect */
@keyframes scrollText {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.scrolling-line a {
  color: #007bff;
  /* Highlight link */
  text-decoration: none;
  font-weight: bold;
}

.scrolling-line a:hover {
  text-decoration: underline;
  /* Underline link on hover */
}

/* Responsive adjustments */
@media (max-width: 768px) {

  /* Scrolling animation for text */
  .scrolling-line span {
    animation: scrollText 5s linear infinite;
  }

  .scrolling-line {
    font-size: 0.875rem;
    /* Adjust font size for smaller screens */
  }
}

@media (max-width: 480px) {
  /* Scrolling animation for text */
  .scrolling-line span {
    animation: scrollText 5s linear infinite;
  }
  .scrolling-line {
    font-size: 0.75rem;
    /* Further reduce font size for mobile */
  }
}

/* feature css */
.container-fluid {
  padding: 20px;
}

.feature-item {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.feature-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.feature-item h1 {
  margin-right: 15px;
  font-size: 2rem;
  color: #007bff;
}

.feature-item h5 {
  margin: 0;
  font-weight: 600;
  font-size: 1.2rem;
  color: #333;
}

@media (max-width: 768px) {
  .feature-item {
    flex-direction: column;
    text-align: center;
  }

  .feature-item h1 {
    margin-bottom: 10px;
    font-size: 1.8rem;
  }
}


/* new offer */
.slider-container {
  margin: 20px auto;
  width: 90%;
  /* Adjust as needed */
}

.slider-item {
  padding: 10px;
  display: flex;
  justify-content: center;
}

.slider-item img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  object-fit: cover;
}