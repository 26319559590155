/* Overlay during loading */
.loading-overlay {
    position: fixed; /* Ensure it covers the entire viewport */
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: linear-gradient(135deg, #ff7e5f, #feb47b); /* Attractive gradient background */
    display: flex;
    flex-direction: column; /* Stack items vertically */
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: hidden; /* Prevent scroll during loading */
}

/* Tagline below the logo */
.tagline {
    font-size: 1.8rem; /* Default size for medium devices */
    margin-top: 1rem;
    color: #3e4e55;
    text-align: center;
    animation: fadeIn 3s ease-in-out;
    font-weight: bold;
}

/* Responsive tagline adjustments */
@media (max-width: 768px) {
    .tagline {
        font-size: 1.4rem; /* Adjust size for smaller screens */
    }
}

@media (min-width: 1200px) {
    .tagline {
        font-size: 2rem; /* Adjust size for larger screens */
    }
}

/* Logo animation */
@keyframes logoZoomInRotate {
    0% {
        transform: scale(0.5) rotate(0deg);
        opacity: 0;
    }

    50% {
        transform: scale(1.5) rotate(180deg);
        opacity: 1;
    }

    100% {
        transform: scale(1) rotate(360deg);
        opacity: 1;
    }
}

/* Logo animation on load */
.logo-animation {
    animation: logoZoomInRotate 2s ease-out forwards;
    max-width: 150px; /* Default size */
    max-height: 150px;
    width: 30vw; /* Responsive scaling */
    height: auto;
}

/* Responsive adjustments for logo animation */
@media (max-width: 768px) {
    .logo-animation {
        max-width: 120px; /* Adjust logo size for smaller screens */
        width: 40vw;
    }
}

@media (min-width: 1200px) {
    .logo-animation {
        max-width: 200px; /* Adjust logo size for larger screens */
    }
}

/* WhatsApp Button */
.whatsapp_float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
}

.whatsapp-icon {
    margin-top: 16px;
}

/* Mobile Styles for WhatsApp */
@media screen and (max-width: 767px) {
    .whatsapp-icon {
        margin-top: 10px;
    }

    .whatsapp_float {
        width: 40px;
        height: 40px;
        bottom: 20px;
        right: 10px;
        font-size: 22px;
    }
}

/* Ensure the app-container spans the entire viewport */
.app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

/* Main content grows to fill space between header and footer */
.main-content {
    background: linear-gradient(90deg, #f4f4f4, #e5f4b5); /* Gradient background */;
    flex: 1; /* This makes the main content fill available space */
    padding: 20px; /* Optional padding */
}

/* Keyframes for fade-in effect */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


