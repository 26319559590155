/* Logo */
.logo-img {
  height: auto;
  max-height: 60px;
  max-width: 150px; /* Consistent sizing */
  object-fit: contain;
  transition: transform 0.3s ease-in-out;
  display: inline-block;
  margin: 0; /* Removes unnecessary margin */
}

.logo-img:hover {
  transform: scale(1.2); /* Hover zoom effect */
}

/* Navbar Container */
.navbar {
  font-size: 20px;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-evenly; /* Ensures equal spacing */
  background: linear-gradient(90deg, #dfee94, #dd5d5d); /* Gradient background */
  padding: 10px 22px;
  z-index: 1000;
  position: fixed; /* Sticky navbar */
}

.navbar-light .navbar-nav {
  display: flex;
  gap: 50px; /* Equal spacing between nav items */
  align-items: center; /* Align items vertically */
}

.navbar-light .navbar-nav .nav-link {
  padding: 20px;
  color: rgb(0, 0, 0);
  transition: color 0.3s ease;
  text-transform: uppercase;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link.active {
  color: #000000;
  font-weight: bold;
}

.nav-link.active {
  color: #ffe0b5;
}

/* Call and Email Buttons */
.cta-buttons {
  display: flex;
  gap: 30px;
  align-items: center;
}

.cta-button {
  padding: 8px 15px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
  text-transform: uppercase;
  text-decoration: none;
  color: rgb(10, 0, 0);
  transition: background 0.3s ease, transform 0.2s;
}

.cta-button.call-now {
  background-color: #34c759; /* Green for call */
}

.cta-button.call-now:hover {
  color: aliceblue;
  background-color: #28a745;
  transform: scale(1.05);
}

.cta-button.email-now {
  background-color: #007bff; /* Blue for email */
}

.cta-button.email-now:hover {
  color: aliceblue;
  transform: scale(1.05);
}

/* Hamburger Icon */
.navbar-toggler {
  border: none;
  background: transparent;
  font-size: 1.5rem;
  color: rgb(0, 0, 0);
  transition: color 0.3s ease;
}

.navbar-toggler:hover {
  color: #ffe0b5;
}

#bar {
  font-size: 1.5rem;
  padding: 7px;
  color: white;
  transition: color 0.3s ease;
}

#bar:hover {
  color: #ffddc1;
}

/* Mobile Adjustments */
@media only screen and (max-width: 991px) {
  .navbar {
    padding: 10px;
    flex-wrap: wrap;
  }

  .logo-img {
    max-height: 50px;
    margin: 0; /* Ensures no space between logo and nav items */
  }

  .navbar-toggler {
    margin-left: auto;
  }

  #navbarSupportedContent {
    width: 100%;
    text-align: left;
  }

  #navbarSupportedContent ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0;
  }

  #navbarSupportedContent ul li {
    width: 100%;
  }

  #navbarSupportedContent ul li a {
    padding: 10px;
    color: rgb(0, 0, 0);
    font-size: 16px;
    text-decoration: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }

  #navbarSupportedContent ul li a:hover {
    background-color: rgba(255, 255, 255, 0.1);
    color: #ffe0b5;
  }

  .cta-buttons {
    width: 100%;
    justify-content: center;
    margin-top: 10px;
    gap: 10px;
  }

  .cta-button {
    width: auto;
    text-align: center;
  }
}

/* QR Modal Styles */

.qr-button .qr-button:hover{
  background-color: #ffddc1;
  padding: 8px 15px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
  text-transform: uppercase;
  text-decoration: none;
  color: rgb(175, 166, 117);
  transition: background 0.3s ease, transform 0.2s;
}

/* QR Modal Styles */
.qr-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Dark background overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
  transition: opacity 0.3s ease;
}

.qr-modal-content {
  background-color: rgb(255, 255, 255);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 90%;
  width: 400px; /* Default width for larger screens */
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.2);
  position: relative; /* Make sure the close button stays inside the content */
}

.qr-modal-close {
  font-size: 1.5rem;
  position: absolute;
  top: 10px;
  right: 10px;
  color: #ffefef;
  cursor: pointer;
  transition: color 0.3s ease;
}

.qr-modal-close {
  color: #000000;
}

.qr-code-image {
  width: 100%;
  max-width: 300px; /* Limit the QR code size */
  height: auto;
  margin-top: 20px;
}

/* Mobile Adjustments for QR Modal */
@media only screen and (max-width: 767px) {
  .qr-modal-content {
    width: 80%; /* Adjust width on smaller screens */
    padding: 15px;
  }

  .qr-code-image {
    max-width: 250px; /* Reduce size of QR code on mobile */
  }
}

