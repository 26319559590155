h1 {
    color: #333;
    font-size: 40px;
    margin-bottom: 30px;
    text-align: center;
}

p {
    color: #555;
    font-size: 18px;
    line-height: 1.5;
    text-align: justify;
}

h2 {
    color: #333;
    font-size: 24px;
    margin-bottom: 15px;
}

#blog-container>div>div>ul {
    color: #555;
    font-size: 18px;
    line-height: 1.5;
    padding-left: 20px
}

#blog-container > div > div > ul > li{
    margin-bottom: 10px;
}

.shop-image-container img {
    max-width: 100%; /* Makes sure the image scales properly */
    height: auto;    /* Maintains aspect ratio */
}
